<template>
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        @open="open"
        :datafilter="filterAll"
        :showpag="true"
        :pages="this.pages"
        :objParams="this.objPages"
        @change="change" 
        @clearFilter="clearFilter"
        @getdata="this.getdata"
        @changelimit="this.changelimit"
    />

    <viewCheck 
        v-if="this.showCheck === true"
        @close="this.showCheck = false"
        :check="this.check"
    />
</template>

<script>
import viewCheck from '@/views/accounts/allAccounts/viewnew/blocks/activity/checks/viewCheck.vue';
import tablecustom from '@/components/globaltable/index.vue';
import {Documents} from "@/API_Dash"

let apiDocs = new Documents()

export default {
    props: ['client', 'obj'],
    components: {
        viewCheck,
        tablecustom,
    },
    data() {
        return {
            showModalCheck: false,
            showTable: false,
            objPages: {
                page: "1",
                pagelimit: "10",
                companies: "",
                workerId: "",
                time: "",
                page: ''
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "reportId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Employee"),
                    text: "",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.workerMutate(item, obj)
                },
                {
                    name: this.$t("date_time"),
                    text: "datetime",
                    align: "left",
                    status: true,
                }
            ],
            showCheck: false,
            check: {},
            pages: {}
        }
    },
    created() {

    },
    mounted() {
        this.getdata()
    },
    methods: {
        changelimit(limit) {
            this.objPages.pagelimit = limit
            this.getdata()
        },
        workerMutate(item, obj) {
            return obj.worker.workerName
        },
        getdata(page) {
            if(page) {
                this.objPages.page = page
            }

            apiDocs.getChecks(this.objPages).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.reports
                    this.pages.links = result.data.links
                    this.pages.total = result.data.total
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка отримання чеків'; 
                    var statusRequest = "error";
                    var api = 'getChecks';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/index.vue';
                    var params = JSON.stringify(this.objPages);
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        open(e) {
            apiDocs.getCheck(e.reportId).then(result => {
                if(result.status === 'done') {
                    this.check = result.data
                    this.showCheck = true
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка отримання чеку'; 
                    var statusRequest = "error";
                    var api = 'getCheck';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/index.vue';
                    var params = e.reportId
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    }
};
</script>